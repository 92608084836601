<template>
  <v-row align="space-between" class="fill-height" justify="center" no-gutters>
    <v-col cols="10" lg="3" md="4" sm="8">
      <v-row align="center" class="mt-16" justify="center" no-gutters>
        <v-col class="mb-5 mt-16" cols="12">
          <kurcc-brand/>
        </v-col>
        <v-col cols="12">
          <span class="headline">{{ $vuetify.lang.t('$vuetify.pages.auth.remindPassword') }}</span>
        </v-col>
        <v-col class="mt-16" cols="12">
          <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
            <v-form @submit.prevent="handleSubmit(forgotPassword)">
              <validation-provider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.pages.auth.email')"
                                   rules="required|email">
                <v-text-field v-model="email" :error-messages="errors"
                              :label="$vuetify.lang.t('$vuetify.pages.auth.email')"
                              autofocus outlined prepend-inner-icon="mdi-email-outline"/>
              </validation-provider>
              <v-btn :disabled="invalid" :loading="loading" color="secondary" tile type="submit" width="100%">
                {{ $vuetify.lang.t('$vuetify.pages.auth.sendMail') }}
              </v-btn>
              <v-row align="center" class="my-10" justify="center" no-gutters>
                Don’t have an account?
                <v-btn :to="{name:'register'}" color="secondary" text>
                  {{ $vuetify.lang.t('$vuetify.pages.auth.registerNow') }}
                </v-btn>
              </v-row>
            </v-form>
          </validation-observer>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <kurcc-support-info/>
    </v-col>
  </v-row>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'KurccForgotPasswordPage',
  components: {
    ValidationObserver,
    KurccBrand: () => import('@/modules/app/components/KurccBrand'),
    KurccSupportInfo: () => import('@/modules/auth/components/KurccSupportInfo')
  },
  data () {
    return {
      email: null,
      loading: false
    }
  },
  methods: {
    forgotPassword () {
      this.$store.dispatch('forgotPassword', { email: this.email })
    }
  }
}
</script>
